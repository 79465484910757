import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { getAxiosClient } from "api/axios";
import { device } from "components/styles/GlobalStyle";
import HeaderDropDown from "../components/UI/HeaderDropDown";
import PageHeaderSub from "../components/PageHeaderSub";
import MyPostGrid from "./communication/Components/MyPostGrid";
import Page from "../components/UI/Elements/Page";
import EventGrid from "./events/Components/EventGrid";
import ProductGrid from "./products/Components/ProductGrid";
import NewsGrid from "components/UI/Blocks/News/Newsgrid";
import { UseOrganizationContext, UseUserContext } from "contexts";
import { getProducts } from "api/services/product";
import { getEvents } from "api/services/event";
import { getPosts } from "api/services/communication";
import DisclosureBar from "components/UI/Bars/DisclosureBar";
import { DISCLOSURESTATUS } from "constants";
import MailView from "./communication/Components/MailView";
import moment from "moment";
import { UseErrorContext } from "contexts";
import thumbnail from "../assets/banner.jpg";
import { ValidateAccess } from "api/services/authorization";
import FollowedEventCalendar from "./events/Components/FollowedEventCalendar";
import MyPosts from "./communication/my-posts";
import VictorBot from "components/VictorBot";

const Container = styled.div`
  padding-bottom: 2rem;
  .dashboard_row {
    max-width: 120rem;
    margin: 0 auto;
    padding: 0 2rem;
    @media ${device.laptop} {
      display: flex;
      gap: 2rem;
    }
  }
  .page__header {
    position: relative;
    padding-top: 16%;
    padding-bottom: 2rem;
    background: black;
    box-shadow: 5px 6px 12px -5px black;
    h1 {
      position: relative;
      z-index: 1;
      margin-left: 2rem;
    }
    .background-image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-position: center;
      background-size: cover;
      opacity: 0.4;
    }
  }
  .disclosure-record {
    margin-bottom: 2rem;
  }
`;

const Dashboard = () => {
  const organizationId = UseOrganizationContext()?.id;
  const [products, setProducts] = useState([]);
  const [events, setEvents] = useState([]);
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [processingData, setProcessingData] = useState([]);
  const { permissions = [] } = UseUserContext();
  const { coverUrl } = UseOrganizationContext();
  const { updateError } = UseErrorContext();
  const [hasAccess1, setHasAccess1] = useState(false);
  const [hasAccess2, setHasAccess2] = useState(false);
  const [posts, setPosts] = useState([]);

  useEffect(async () => {
    if (!organizationId) return;
    loadProducts();
    loadEvents();
    loadPosts();
    const access1 = ValidateAccess(["InvestorInsights.Read"], permissions);
    setHasAccess1(access1);
    const access2 = ValidateAccess(["Communication.Read"], permissions);
    setHasAccess2(access2);
    const processingJobsData = await getProcessingJobs();
    setProcessingData(processingJobsData);
  }, [organizationId]);

  const loadPosts = async () => {
    if (!organizationId) return;
    try {
      var params = {
        page: 1,
        from: null,
        to: null,
        status: "Published",
      };

      const response = await getPosts(organizationId, params);

      let data = response.data;

      setPosts(data);
    } catch (error) {
      console.log("error2", error);
    }
  };

  const getProcessingJobs = async () => {
    if (!organizationId) return;
    const axiosClient = await getAxiosClient();
    try {
      return await axiosClient
        .get(`/loader/organizations/${organizationId}/processingJobs?pageSize=3`)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    } catch (error) {
      console.log("error2", error);
    }
  };

  const loadProducts = async () => {
    try {
      let access = ValidateAccess(["Products.Read"], permissions);
      if (!access) return;

      const productsData = await getProducts(organizationId, {
        pageSize: 6,
      });
      setProducts(productsData);
    } catch (err) {
      updateError(err);
    }
  };

  const loadEvents = async () => {
    try {
      let access = ValidateAccess(["Events.Read"], permissions);
      if (!access) return;

      // if (past) {
      //   const eventsDataPast = await getEvents(organizationId, {
      //     pageSize: 6,
      //     to: moment().startOf("day").format("YYYY-MM-DD"),
      //     order: "DESC",
      //   });
      //   setEventsPast(eventsDataPast);
      //   return;
      // }
      const upcomingEventsData = await getEvents(organizationId, {
        pageSize: 6,
        from: moment().startOf("day").format("YYYY-MM-DD"),
        order: "ASC",
      });
      setUpcomingEvents(upcomingEventsData);

      const eventsData = await getEvents(organizationId, {
        order: "ASC",
      });
      setEvents(eventsData);
    } catch (err) {
      updateError(err);
    }
  };

  return (
    <Page fullWidth={true}>
      <div className="page__inner">
        <Container>
          <div className="page__header">
            <h1>DASHBOARD</h1>
            <PageHeaderSub />
            <div className="background-image" style={{ backgroundImage: "url('" + coverUrl ?? thumbnail + "')" }}></div>
          </div>
          <div className="dashboard_row">
            <HeaderDropDown titleHeader="Upcoming Actions" bodyStyle={{ maxHeight: "45vh" }} link="/events" roles={["Events.Read"]}>
              <EventGrid canEdit={false} events={upcomingEvents} refresh={loadEvents} direction={"column"} />
            </HeaderDropDown>
            <div style={{width: "50%"}}>
              <HeaderDropDown titleHeader="Posts" bodyStyle={{ maxHeight: "45vh" }} link="/posts" roles={["Posts.Read"]}>
                <MyPostGrid posts={posts} />
              </HeaderDropDown>
            </div>
          </div>
          <div className="dashboard_row">
            <HeaderDropDown titleHeader="Calendar" bodyStyle={{ maxHeight: "80vh" }} link="/events" roles={["Events.Read"]}>
              <FollowedEventCalendar events={upcomingEvents} />
            </HeaderDropDown>
          </div>
          <div className="dashboard_row">
            <HeaderDropDown titleHeader="Shares" link="/products" roles={["Products.Read"]}>
              <ProductGrid canEdit={false} products={products} refresh={loadProducts} direction={"column"} />
            </HeaderDropDown>
            <HeaderDropDown titleHeader="Latest news" bodyStyle={{ maxHeight: "45vh", minHeight: "45vh" }}>
              {<NewsGrid direction={"column"} asCompany={true} />}
            </HeaderDropDown>
          </div>
          {hasAccess1 && (
            <div className="dashboard_row">
              <HeaderDropDown titleHeader="Last shareholder campaigns" link="/investor-insights/loader">
                <div className="history__container">
                  {processingData &&
                    processingData.map(
                      (item, key) =>
                        item.status === DISCLOSURESTATUS.SENTMAILING && (
                          <div key={key} className="disclosure-record">
                            <DisclosureBar
                              key={key}
                              processingJobId={item.id}
                              title={item.disclosureRecordId}
                              status={item.status}
                              _onClick={() => _sendInvites(item.id)}
                              date={item.disclosureRequestDate}
                              product={item.productName}
                              updatedTime={item.updatedTime}
                              data={item}
                              stats
                              loadStats
                            />
                          </div>
                        )
                    )}
                </div>
              </HeaderDropDown>
            </div>
          )}
          {hasAccess2 && (
            <div className="dashboard_row">
              <HeaderDropDown titleHeader="Questions" link="/communication">
                <MailView />
              </HeaderDropDown>
            </div>
          )}
        </Container>
      </div>
    </Page>
  );
};

export default Dashboard;
