import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Modal from "components/UI/Modal";
import Attachment from "components/UI/Icons/Attachment";
import DocumentGrid from "./DocumentGrid";
import { FormatDateTimeDisplay } from "api/helpers/datahelper";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import HTMLRenderView from "components/UI/Blocks/HTMLRenderView";
import draftToHtml from "draftjs-to-html";

const Container = styled.div`
  margin-top: 0rem;
  overflow: auto;
  .post {
    background-color: rgba(26,26,26,0.9);
    margin: 0 auto;
    margin-bottom: 1.6rem;
    padding: 2.4rem;
    border-radius: 0.8rem;
    box-shadow: black 5px 6px 12px -5px;
    &__body {
      display: flex;
      justify-content: space-between;
      &__header {
        display: flex;
        margin-bottom: 2rem;
        &-image {
          width: 4rem;
          height: 4rem;
          object-fit: contain;
        }
        &-content {
         margin-left: 1.2rem;
         span{
          font-size: 1.2rem;
          color: var(--color-light-grey);
         }
        }
      }
      &__left {
        &__content {
          color: var(--color-light-grey);
          & > * {
            margin-top: 1rem;
          }
          a {
            text-decoration: underline;
            color: var(--color-accent-prim);
          }
          strong {
            font-weight: 500;
          }
          ul {
            list-style: disc;
            margin: 1.2rem 1.2rem;
            li {
              margin: 0.6rem;
              ul {
                margin-top: 0;
                list-style: circle;
              }
            }
          }
        }
      }
      &__right {
        font-size: 1.2rem;
        opacity: 0.8;
        text-align: right;
        display: flex;
        flex-direction: column;
        align-items: end;
        & > * {
          margin-top: 0.6rem;
        }
        &__actions {
          display: flex;
          & > * {
            margin-left: 0.8rem;
          }
        }
      }
      .attachments {
        font-size: 1.6rem;
        svg {
          path {
            fill: white;
          }
        }
      }
    }
  }
  .images {
    margin: 2rem;
    padding-top: 1rem;
  }
  .slider {
    .slick-slide img {
      display: block;
      max-height: 250px;
      width: auto;
      margin: auto;
    }
    .slick-dots li button::before {
      color: var(--color-accent-prim);
    }
  }
`;

const MyPostComponent = ({ post, refresh }) => {
  console.log(post);
  const [showModalAttachments, setShowModalAttachments] = useState(false);
  const [images, setImages] = useState([]);
  const [documents, setDocuments] = useState([]);

  useEffect(async () => {
    const img = post.attachments.filter((x) => x.type == "Image");
    setImages(img);
    const docs = post.attachments.filter((x) => x.type == "Document");
    setDocuments(docs);
  }, []);

  const settings = {
    dots: true,
    infinite: false,
    lazyLoad: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <Container>
      {showModalAttachments && (
        <Modal title="Documents" showClose hideActions onClose={() => setShowModalAttachments(false)}>
          <DocumentGrid documents={documents} isReadOnly />
        </Modal>
      )}
      <div className="post">
        <div className="post__body">
          <div className="post__body__left">
            <div className="post__body__header">
              {post?.organization?.logoUrl && <img className="post__body__header-image" src={post?.organization?.logoUrl} />}
              <div className="post__body__header-content">
                <div>{post.organization.name}</div>
                <div>
                  <span>{`${FormatDateTimeDisplay(post.publishedTime)}`}</span>
                </div>
              </div>
            </div>
            <h2>{post.title}</h2>
            <div className="post__body__left__content">
              <HTMLRenderView content={draftToHtml(JSON.parse(post.message))} />
            </div>
          </div>
          <div className="post__body__right">
            {documents.length > 0 && (
              <div onClick={() => setShowModalAttachments(true)} className="icon-button attachments">
                {documents.length}
                <Attachment />
              </div>
            )}
          </div>
        </div>
        {images.length > 0 && (
          <div className="images">
            <Slider {...settings} className="slider">
              {images?.map((image, idx) => (
                <div key={idx} className="cover">
                  <img src={image.uri} alt="" />
                </div>
              ))}
            </Slider>
          </div>
        )}
      </div>
    </Container>
  );
};

MyPostComponent.defaultProps = {};

export default MyPostComponent;
