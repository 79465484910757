import React, { useEffect, useState, Suspense } from "react";
import GlobalStyle from "./components/styles/GlobalStyle";
import Grid from "./components/styles/Grid";
import Layout from "./components/Layout.js";
import Error from "./components/Error.js";
import { BrowserRouter as Router } from "react-router-dom";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./authConfig";
import { MsalProvider } from "@azure/msal-react";
import { LoginRoutes, AppRoutes } from "./routes";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { ErrorContext, OrganizationContext, UserContext } from "./contexts";
import { getOrganization, getOrganizationProfileSection } from "./api/services/organization";
import { getOrganzationUser, getUser, addUser } from "api/services/user";
import {WebsocketProvider} from './contexts/InfrontContext';
import languageSettings from "language";
import { useTranslation } from 'react-i18next';

window.msalInstance = new PublicClientApplication(msalConfig);

export default function App() {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [permissions, setPermissions] = useState([]);
  const [userName, setUserName] = useState("");
  const [userData, setUserData] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const { t, i18n } = useTranslation();

  const updateOrganizationData = async (id) => {
    if(id){
      const orgData = await getOrganization(id);
      const profileSection = await getOrganizationProfileSection(id);
      setData({ ...orgData, profileSection: profileSection });
      setLoading(false);
    }
  };

  const updateUserData = async ( permissionData ) => {
    setPermissions(permissionData);    
  };

  const updateUserName = async ( userName ) => {
    setUserName(userName);    
  };

  // Get user if not found add a new one
  const _getUser = async () => {
    try{ 
      var user = await getUser();
      
      if (user?.languageCode){
        i18n.changeLanguage(user.languageCode.toLowerCase())
        languageSettings.Code = user.languageCode;
      }
    }
    catch(error){    
      //if we're not on the registration page and the user is not found, we will create one. 
      if (error.response.status == "404" && !document.URL.includes("/registration/")){
        user = await addUser()
      }
    }
    return user;
  };

  const updateOrgUserData = async ( account ) => {    
    setLoading(true);
    if (account) {
      var orgUser = await getOrganzationUser();
      var user = await _getUser();

      setUserData(user);
      if (user){
        await updateUserName(user.email);
      }
      if (orgUser)
      {
        await updateOrganizationData(orgUser.organizationId);
        await updateUserData(orgUser.permissions);   
      }
    }    
    setLoading(false);
  };

  const updateError = async ( error ) => {
    setErrorMessage(error);    
  };

  return (
      <MsalProvider instance={window.msalInstance}>
        <Router>
          <GlobalStyle />
          <Grid />
          <AuthenticatedTemplate>
            <OrganizationContext.Provider value={{ ...data, updateOrganizationData, updateOrgUserData, loading }}>
              <UserContext.Provider value={{ permissions, updateUserData, userName, updateUserName, userData }}>
              <WebsocketProvider>
                <ErrorContext.Provider value={{ errorMessage, updateError }}>
                  <Error/>
                  <Layout>
                    <AppRoutes />
                  </Layout>
                </ErrorContext.Provider>
                </WebsocketProvider>
              </UserContext.Provider>
            </OrganizationContext.Provider>
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <LoginRoutes />
          </UnauthenticatedTemplate>
        </Router>
      </MsalProvider>
  );
}
